import React from 'react';
import {useTranslation} from "react-i18next";
import Paragraph from "../../../components/paragraph/Paragraph";
import ParagraphWithList from "../../../components/paragraphWithList/ParagraphWithList";
import "./PrivacyPolicy.css"

const PrivacyPolicy = () => {
    const {t} = useTranslation()

    return (
        <div className="privacyPolicy">
            <div className="container">
                <h2 className="title">{t('privacyPolicy')}</h2>
                <div className="privacyPolicy__content">
                    <Paragraph title={t('Introduction')} subtitle={t('IntroductionPrivacyPolicyDesc')}/>
                    <ParagraphWithList title={t('InformationWeCollect')} subtitle={t('InformationWeCollectDesc')} list={[{desc1: t('InformationWeCollectListFirstDesc'), desc2: t('InformationWeCollectListSecondDesc'), desc3: t('InformationWeCollectListThirdDesc')}]}/>
                    <ParagraphWithList title={t('HowWeUseYourData')} subtitle={t('HowWeUseYourDataDesc')} list={[{desc1: t('HowWeUseYourDataListFirstDesc'), desc2: t('HowWeUseYourDataListSecondDesc'), desc3: t('HowWeUseYourDataListThirdDesc')}]}/>
                    <Paragraph title={t('DataStorage')} subtitle={t('DataStorageDesc')}/>
                    <Paragraph title={t('DataSharing')} subtitle={t('DataSharingDesc')}/>
                    <Paragraph title={t('DataDeletionPrivacyPolicy')} subtitle={t('DataDeletionPrivacyPolicyDesc')}/>
                    <Paragraph title={t('DataSecurity')} subtitle={t('DataSecurityDesc')}/>
                    <ParagraphWithList title={t('UserRights')} subtitle={t('UserRightsDesc')} list={[{desc1: t('UserRightsListFirstDesc'), desc2: t('UserRightsListSecondDesc'), desc3: t('UserRightsListThirdDesc')}]}/>
                    <Paragraph title={t('ChangesToPrivacyPolicy')} subtitle={t('ChangesToPrivacyPolicyDesc')}/>
                    <Paragraph title={t('ContactInformationPrivacyPolicy')} subtitle={t('ContactInformationDescPrivacyPolicy')} link={t('support')}/>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;