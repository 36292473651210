import React from 'react';
import Home from "./Home/Home";

const HomePage = () => {
    return (
        <div>
            <Home/>
        </div>
    );
};

export default HomePage;