import React from 'react';
import TermsOfUse from "./TermsOfUse/TermsOfUse";

const TermsOfUsePage = () => {
    return (
        <div>
            <TermsOfUse/>
        </div>
    );
};

export default TermsOfUsePage;