import React, {useEffect, useState} from 'react';
import './Header.css'
import Link from "../link/Link";
import {useTranslation} from "react-i18next";
import Select from "../select/Select";

const Header = ({selectedOption, setSelectedOption, options}) => {
    const {t, i18n} = useTranslation()
    const [burger, setBurger] = useState(false)

    const changeLanguage = (e) => {
        i18n.changeLanguage(e.target.value)
        setSelectedOption(e.target.value)
        setBurger(false)
    }

    useEffect(() => {
        burger ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto'
    }, [burger])

    return (
        <header className="header">
            <div className="container">
                <nav className="header__nav">
                    <Link to="/" text={t('home')}/>
                    <Link to="/termsOfUse" text={t('termsOfUse')}/>
                    <Link to="/privacyPolicy" text={t('privacyPolicy')}/>
                    <Link to="/support" text={t('support')}/>
                    <Select value={selectedOption} disabledOpt="Language" options={options} onChange={changeLanguage}/>
                </nav>
                <div className="burger__block">
                    <div className={`burger__menu ${burger && "burger__color"}`} onClick={() => !burger ? setBurger(true) : setBurger(false)}/>
                    <div className={`burger__menu ${burger && "burger__color"}`} onClick={() => !burger ? setBurger(true) : setBurger(false)}/>
                    <div className={`burger__menu ${burger && "burger__color"}`} onClick={() => !burger ? setBurger(true) : setBurger(false)}/>
                    {
                        burger && <div className="burger">
                            <div className="burger__content">
                                <Link onClick={() => setBurger(false)} to="/" text={t('home')}/>
                                <Link onClick={() => setBurger(false)} to="/termsOfUse" text={t('termsOfUse')}/>
                                <Link onClick={() => setBurger(false)} to="/privacyPolicy" text={t('privacyPolicy')}/>
                                <Link onClick={() => setBurger(false)} to="/support" text={t('support')}/>
                                <Select value={selectedOption} disabledOpt="Language" options={options} onChange={changeLanguage}/>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </header>
    );
};

export default Header;