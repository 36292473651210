import React, {useEffect, useState} from 'react';
import HomePage from "./pages/HomePage/HomePage";
import {Routes, Route, useLocation} from "react-router-dom"
import Header from "./components/header/Header";
import TermsOfUsePage from "./pages/TermsOfUsePage/TermsOfUsePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage";
import Footer from "./components/footer/Footer";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import SupportPage from "./pages/SupportPage/SupportPage";

const App = () => {
    const [selectedOption, setSelectedOption] = useState('')
    const location = useLocation()

    const languages = [
        {id: 1, value: "en", text: "English"},
        {id: 2, value: "ru", text: "Русский"}
    ]

    const titles = {
        "/": "Mystic Arcana - Tarot predictions",
        "/termsOfUse": "Mystic Arcana - Terms of Use",
        "/privacyPolicy": "Mystic Arcana - Privacy Policy",
        "/support": "Mystic Arcana - Support"
    }

    const truePaths = ["/", "/termsOfUse", "/privacyPolicy", "/support"];
    const shouldShow = truePaths.includes(location.pathname);

    useEffect(() => {
        document.title = titles[location.pathname];
    }, [location]);

  return (
      <div className="app">
          {
              shouldShow && <Header selectedOption={selectedOption} setSelectedOption={setSelectedOption} options={languages}/>
          }
          <Routes>
              <Route path="*" element={<NotFoundPage selectedOption={selectedOption} setSelectedOption={setSelectedOption} options={languages}/>}/>
              <Route path="/" element={<HomePage/>}/>
              <Route path="/termsOfUse" element={<TermsOfUsePage/>}/>
              <Route path="/privacyPolicy" element={<PrivacyPolicyPage/>}/>
              <Route path="/support" element={<SupportPage/>}/>
          </Routes>
          {
              shouldShow && <Footer selectedOption={selectedOption} setSelectedOption={setSelectedOption} options={languages}/>
          }
      </div>
  );
};

export default App;
