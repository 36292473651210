import i18n from "i18next"
import {initReactI18next} from "react-i18next/initReactI18next";
import LanguageDetector from 'i18next-browser-languagedetector'
import enResources from "./locales/en/translation.json"
import ruResources from "./locales/ru/translation.json"

i18n.use(LanguageDetector).use(initReactI18next).init({
    fallbackLng: 'en',
    lng: 'en',
    interpolation: {
        escapeValue: false
    },
    resources: {
        en: {
            translation: enResources
        },
        ru: {
            translation: ruResources
        }

    }
})
