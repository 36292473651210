import React from 'react';
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";

const PrivacyPolicyPage = () => {
    return (
        <div>
            <PrivacyPolicy/>
        </div>
    );
};

export default PrivacyPolicyPage;