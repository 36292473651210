import React from 'react';
import Link from "../../components/link/Link";
import {useTranslation} from "react-i18next";
import "./NotFoundPage.css"
import Select from "../../components/select/Select";

const NotFoundPage = ({selectedOption, setSelectedOption, options}) => {
    const {t, i18n} = useTranslation()

    const changeLanguage = (e) => {
        i18n.changeLanguage(e.target.value)
        setSelectedOption(e.target.value)
    }

    return (
        <div className="notFound">
            <h2 className="title">{t('NotFound')}</h2>
            <Link to="/" text={t('NotFoundDesc')}/>
            <Select value={selectedOption} disabledOpt="Language" options={options} onChange={changeLanguage}/>
        </div>
    );
};

export default NotFoundPage;