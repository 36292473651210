import React from 'react';
import {useTranslation} from "react-i18next";
import "./Support.css"

const Support = () => {
    const {t} = useTranslation()
    return (
        <div className="support">
            <div className="container">
                <div className="support__content">
                    <h2 className="title">{t('support')}</h2>
                    <p className="subtitle">{t('supportDesc')}</p>
                    <a href="mailto:mystictarotarcana@gmail.com" className="subtitle">email: mystictarotarcana@gmail.com</a>
                </div>
            </div>
        </div>
    );
};

export default Support;