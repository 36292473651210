import React from 'react';
import images from "./Images";
import Card from "./Card/Card";
import "./Home.css"
import {useTranslation} from "react-i18next";

const Home = () => {
    const {t} = useTranslation()

    return (
        <div className="home">
            <div className="container">
                <div className="aboutUs">
                    <h2 className="title">{t('aboutUs')}</h2>
                    <p className="subtitle">{t('aboutUsFirstDesc')}</p>
                    <p className="subtitle">{t('aboutUsSecondDesc')}</p>
                    <ul className="aboutUs__list">
                        <li className="list__item"><p className="subtitle">{t('aboutUsListFirstDesc')}</p></li>
                        <li className="list__item"><p className="subtitle">{t('aboutUsListSecondDesc')}</p></li>
                        <li className="list__item"><p className="subtitle">{t('aboutUsListThirdDesc')}</p></li>
                    </ul>
                    <p className="subtitle">{t('aboutUsThirdDesc')}</p>
                </div>
                <div className="home__content">
                    <h3 className="title__small">{t('MajorArcana')}</h3>
                    <div className="home__cards">
                        {
                            images[0].arcana.map(item => {
                                return (
                                    <Card src={item.image} text={t(item.text)} key={item.id}/>
                                )
                            })
                        }
                    </div>
                    <h3 className="title__small">{t('SuitOfCups')}</h3>
                    <div className="home__cards">
                        {
                            images[0].cups.map(item => {
                                return (
                                    <Card src={item.image} text={t(item.text)} key={item.id}/>
                                )
                            })
                        }
                    </div>
                    <h3 className="title__small">{t('SuitOfPentacles')}</h3>
                    <div className="home__cards">
                        {
                            images[0].pentacles.map(item => {
                                return (
                                    <Card src={item.image} text={t(item.text)} key={item.id}/>
                                )
                            })
                        }
                    </div>
                    <h3 className="title__small">{t('SuitOfSwords')}</h3>
                    <div className="home__cards">
                        {
                            images[0].swords.map(item => {
                                return (
                                    <Card src={item.image} text={t(item.text)} key={item.id}/>
                                )
                            })
                        }
                    </div>
                    <h3 className="title__small">{t('SuitOfWands')}</h3>
                    <div className="home__cards">
                        {
                            images[0].wands.map(item => {
                                return (
                                    <Card src={item.image} text={t(item.text)} key={item.id}/>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;