import React from 'react';

const ParagraphWithList = ({title, subtitle, list}) => {
    return (
        <div className="paragraph">
            <h3 className="title__small">{title}</h3>
            <p className="subtitle">{subtitle}</p>
            <ul className="paragraph__list">
                <li className="list__item"><p className="subtitle">{list[0].desc1}</p></li>
                <li className="list__item"><p className="subtitle">{list[0].desc2}</p></li>
                <li className="list__item"><p className="subtitle">{list[0].desc3}</p></li>
            </ul>
        </div>
    );
};

export default ParagraphWithList;