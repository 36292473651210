import React from 'react';
import Link from "../link/Link";
import "./Footer.css"
import Select from "../select/Select";
import {useTranslation} from "react-i18next";

const Footer = ({selectedOption, setSelectedOption, options}) => {
    const {t, i18n} = useTranslation()

    const changeLanguage = (e) => {
        i18n.changeLanguage(e.target.value)
        setSelectedOption(e.target.value)
    }
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer__content">
                    <Link to="/" text={t('home')}/>
                    <Link to="/termsOfUse" text={t('termsOfUse')}/>
                    <Link to="/privacyPolicy" text={t('privacyPolicy')}/>
                    <Link to="/support" text={t('support')}/>
                    <Select value={selectedOption} disabledOpt="Language" options={options} onChange={changeLanguage}/>
                </div>
                <p className="subtitle__small">© 2024 Mystic Arcana. {t('rights')}</p>
            </div>
        </footer>
    );
};

export default Footer;